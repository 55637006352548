jQuery(document).ready(function ($) {

    $('[data-tilt]').tilt({
        maxTilt: 5,
        speed: 1200
    })

});

// Эффект рассеения воды при перемещении курсора
//=require ripple.js

// Эффект волны букв в предложении. (лого)
//=require moveout.js

// Эффект растягивания букв при наведении (ФИО)
//=require magneto.js

// Анимирование портфолио
//=require portfolio.js

// Счетчик слайдов в портфолио
//!=require slidesCounter.js


// MODULES
//!=require modules/preloader.js
//!=require modules/jquery.elastic-input.js
//!=require modules/jquery.pagescroll.js
//!=require modules/jquery.phonemask.js
//!=require modules/jquery.validation.js

jQuery(document).ready(function ($) {

    var ripple = $('.ripple');
    if ($('.ripple').length > 0 ) {
        ripple.ripples({
            resolution: 512,
            dropRadius: 10, //px
            perturbance: 0.04,
            interactive: true
        });
    };

});